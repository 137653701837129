<template>
  <div class="headerView" :style='"background:" + bgColor + ";background-size:cover"'>
    <div class="head_content">
      <img src="../assets/images/logo.png" alt="">
      <ul class="nav">
        <li v-for="(item,index) in navList" :key="index" >
          <router-link v-if='item.url' :to="'/' + item.url">{{item.name}}</router-link>
          <ol v-if="index == 4">
            <li v-for="(Iitem,Iindex) in secondNavList" :key="Iindex">
             <router-link :to="Iitem.url">{{Iitem.name}}</router-link>
            </li>
          </ol>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getNav } from '@/request/api'
export default {
  name: 'headerView',
  data(){
    return{
      navList:[],
      secondNavList:[
        {name:'金融服务平台',url:'/finance'},
        {name:'智慧仓储系统',url:'/storage'},
        {name:'智慧物流平台',url:'/logistics'},
        {name:'智慧园区系统',url:'/park'},
        {name:'加工交易平台',url:'/process'},
      ]
    }
  },
  created(){
    
  },
  mounted(){
    this.getNav();
  },
  props:{
    bgColor: {
      type: String,
      // default: 'rgba(255,255,255,0.3)',
      default: 'rgba(233,228,224,0.8)',
    },
  }, 
  methods:{
    getNav(){
      getNav().then((res)=>{
        this.navList = res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.headerView{
  background:rgba(255,255,255,.7)!important;
  &:hover{
    // background:rgba(255,255,255,.4)!important;
    background:rgba(255,255,255,1)!important;
  }
  .head_content{
    width:1400px;
    height:70px;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 100px;
    box-sizing:border-box;
    .nav{
      display:flex;
      a{
        color: #4B4848;
        padding-top:15px;
        padding-bottom:12px;
        margin-left:28px;
        text-decoration: none;
      }
      li{
        height:70px;
        line-height:70px;
        position:relative;
        &:hover a{
          color:#E7351B;
        }
        ol{
          width:130px;
          position:absolute;
          background:#ffffff;
          top:70px;
          left:-5px;
          display:none;
          text-align:center;
          li{
            height:40px;
            line-height:40px;
            a{
              margin-left:0;
              padding-bottom:8px;
              color: #4B4848!important;
              font-size:14px;
            }
            &:hover{
              a{
                color:#E7351B!important;
              }
            }
          }
          .router-link-active{
            border-bottom:2px solid #E7351B!important;
            color:#E7351B!important;
          }
        }
        &:hover ol{
          display:block;
        }
      }
      .router-link-active{
        border-bottom:2px solid #E7351B;
        color:#E7351B;
      }
    }
  }
}
</style>
