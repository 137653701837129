/**
 * api接口统一管理
 */
let portalServer = "api";//开发环境暂定值ggzyjy-portal-XXX
import { getApi, postApi } from './http'
// post请求方式
export const testPost = params => postApi('api/v1/testPost', params)
// get请求方式
export const testGet = params => getApi('api/v1/testGet', params)

// 导航菜单
export const getNav = params => getApi(`${portalServer}/index/nav`, params, false)
// 联系我们信息
export const getContact = params => getApi(`${portalServer}/index/link`, params, false)
// 企业概况
export const getAbout = params => getApi(`${portalServer}/index/about`, params, false)
// 新闻列表
export const getNewsList = params => getApi(`${portalServer}/index/newsList`, params, false)
// 新闻详情
export const getNewsDetail = params => getApi(`${portalServer}/index/newsDetail`, params, false)
// 站点信息
export const getConfigInfo = params => getApi(`${portalServer}/index/config_info`, params, false)
