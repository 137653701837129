// 引入axios
import axios from 'axios'
import { Message } from 'element-ui';
import { baseUrl } from './baseUrl'
import router from'../router'
// 引入qs模块，用来序列化post类型的数据
import qs from 'qs'
// import {router} from "./api";
axios.defaults.baseURL = baseUrl;  // 根据环境变量设置默认地址

axios.defaults.timeout = 50000 // 设置超时时间为50秒（后续根据实际需求修改）

// 设置请求拦截
axios.interceptors.request.use(config => {
  // config.headers.Authorization = 'Bearer' + 'asd13as1d321as32d'
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  // 每次发送请求前判断是否存在token，如果存在，则同意再http请求的header都加上token
  // 即使在本地存在token，也有可能token已失效，所以在响应拦截器
  // const token = store.state.token
  const token = localStorage.getItem('token')
  token && (config.headers.Authorization = token)
  return config
},
  error => {
    return Promise.reject(error)
  })

// 200 / 000000 成功
// 未登录/token失效/token不一致
// post/get请求

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code == '1') {
      return Promise.resolve(response)
    } else if (response.data.code == '10001') {
      // token为空
      if(window.location.hash !='#/login'){
        Message({
          type: "warning",
          message: response.data.msg
        });
        window.sessionStorage.clear()
        window.localStorage.removeItem('token')
        router.push('/login')
      }
    } else if (response.data.code == '10002') {
      // token过期
      if(window.location.hash !='#/login'){
        Message({
          type: "warning",
          message: response.data.msg
        });
        window.sessionStorage.clear()
        window.localStorage.removeItem('token')
        router.push('/login')
      }
    } else {
      Message({
        type: "warning",
        message: response.data.msg
      });
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if(error.response.data.code == '10002' && window.location.hash !='#/login'){
      Message({
        type: "warning",
        message: error.response.data.msg
      });
      window.sessionStorage.clear()
      window.localStorage.removeItem('token')
      router.push('/login')
    }
  }
)
// 封装请求
/**
 * 封装get方法，对应get请求
 * @param { String } url [请求的url地址]
 * @param { Object } params [请求时携带的参数]
 */
export function getApi(url, params, useForm = true) {
  return new Promise((resolve, reject) => {
    // axios.get(url,{params})
    axios({
      method: 'GET',
      url,
      params,
      useForm,
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
/**
 *  delete 封装
 * @param {String} url 
 * @param {Object} params 
 * @param {Boolean} useForm 
 * @returns 
 */
export function deleteApi(url, params, useForm = true) {
  return new Promise((resolve, reject) => {
    // axios.get(url,{params})
    axios({
      method: 'delete',
      url,
      params,
      useForm,
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

/**
 * 封装post方法，对用post请求
 * @param { String } url [请求的url地址]
 * @param { Object } params [请求时携带的参数]
 */
export function postApi(url, params, useForm = true) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url,
      data: useForm ? qs.stringify(params) : params,
      useForm,
      headers: {
        // "Access-Control-Max-Age":'*', // 处理axios请求请求了两次
        'Content-Type': useForm ? 'application/x-www-form-urlencoded;charset=UTF-8' : 'application/json;charset=UTF-8'
      }
    })
      // axios.post(url, qs.stringify(params))
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
